import { Empty, List, Result } from "antd";
import React from "react";

import { DynamicComponent } from "./DynamicComponent";
import { DynamicProps, useDynamic } from "../HOC/withDynamicProps";

import { useDynamicData } from "../context/DynamicDataProvider";
import { parseTemplate } from "../utils/parseTemplate";

const Wrapper = ({ item, stores = [], ...props }: any) => {

	stores = [{ name: "item", type: 'data', options: { initialValue: item } }, ...stores];

	return <DynamicComponent {...props} stores={stores} />;
}
const ListItem = ({ item, titleTpl, descriptionTpl }: any) => {
	console.log(descriptionTpl)
	return <List.Item>
		<List.Item.Meta
			title={titleTpl && <Wrapper {...titleTpl} item={item} />}
			description={descriptionTpl && <Wrapper {...descriptionTpl} item={item} />}
		/>
	</List.Item>
}

export const ListView = ({ wrapper, items, titleTpl, descriptionTpl, noDataMessage, ...rest }: any) => {
	if (items.length === 0) {
		return <Empty description={noDataMessage} />
	}
	return (<List bordered itemLayout="horizontal" {...rest}
		dataSource={items}
		renderItem={(item: any) => <ListItem item={item} titleTpl={titleTpl} descriptionTpl={descriptionTpl} />}
	/>);
}

ListView.withDynamicProps = (Component: React.ComponentType<any>) => {
	return ({ children, titleTpl, descriptionTpl, ...props }: DynamicProps) => {
		const dynamicProps = useDynamic(props);
		return (<Component {...dynamicProps} titleTpl={titleTpl} descriptionTpl={descriptionTpl}>{children}</Component>)
	}
}