export function isDynamic(o: any) {
	for (var i in o) {
		if (typeof o[i] === 'string') {
			if ((o[i].startsWith('${{') || o[i].startsWith('{{')) && o[i].endsWith("}}"))
				return true;
		}
		else if (!!o[i] && typeof (o[i]) == "object") {
			const result = isDynamic(o[i]);
			if (result) return true;
		}
	}
	return false;
}

export const isDEV = () => process.env.NODE_ENV === "development";