import { Form as AntdForm } from "antd";
import { merge } from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import portalManager from "../app/PortalManager";
import { useAsyncCallback } from "../hooks/useAsyncCallback";

export interface FormContextInterface {
	busy: boolean;
}
const FormContext = createContext<FormContextInterface>({ busy: false });
export const useFormContext = () => useContext(FormContext);

export const Form = ({ children, postTo, patchTo, id, ...props }: any) => {
	const [form] = AntdForm.useForm();
	const [busy, setBusy] = useState(false);
	props = merge({
		scrollToFirstError: true,
		colon: false,
		labelWrap: true,
		layout: "vertical",
		wrapperCol: { span: 12 }
	}, props);
	const [submit] = useAsyncCallback(async () => {
		setBusy(true);
		let response;
		//TODO: find better way to diff between add/edit
		const data = form.getFieldsValue(true, ({touched}) => !patchTo || touched); // use patchTo for edit
		console.log(form)
		console.log(data)
		Object.keys(data).forEach(key => {
			console.log("---------------------------------------------------------------------------------");
			console.log(key, data[key]);
			if (data[key]?.file) {

				const id = data[key].file?.response?.data?.id
				data[key] = id;
			}
		});
		if (postTo) {
			response = await portalManager.api.transport.post(postTo, data)
		} else if (patchTo) {
			response = await portalManager.api.transport.patch(patchTo, data)
		}
		setBusy(false);
	}, [form, postTo]);

	return (<FormContext.Provider value={{ busy }}>
		<AntdForm form={form} {...props} onFinish={submit}>{children}</AntdForm>
	</FormContext.Provider>);
}

