import { QueryClient } from "@tanstack/react-query";
import { Content } from "../components/Content";
import { LanguageSwitcher } from "../components/LanguageSwitcher";
import { PortalView } from "../components/PortalView";
import { DirectusClient } from "../directus/DirectusClient";
import { initI18n } from "../localization/i18n";
import { DataStore } from "./data-store";
import { registerComponents } from "./dynamic-components-registry";

export const queryClient = new QueryClient();
class PortalManager {

	initialized = false;
	config: any = {};

	private _api!: DirectusClient;
	dataStores: Record<string, DataStore> = {};

	get api(): DirectusClient {
		if (!this.initialized) throw new Error("Cannot use api before portal manager initialization")
		return this._api;
	}
	get languages(): string[] {
		return this.config.languages;
	}
	get defaultLanguage(): string {
		return this.config.defaultLanguage;
	}
	get theme(): any {
		return this.config.theme;
	}
	private _user: any;
	get user(): any {
		return this._user;
	}
	async init(configPath = "/config.json") {
		// load config
		const response = await fetch(configPath);
		const config = await response.json();
		this._api = new DirectusClient(config.apiUrl as string);
		await this._api.initAuth();

		const user = await this._api.users.me.read({ fields: ["*.*"] });
		this._user = user;
		try {
			// @ts-ignore
			const code = await (await fetch(`/portals/eia/${user.role.name}.js`)).text();
			/* eslint-disable no-eval */
			let portalConfig = eval(code);
			this.config = portalConfig;
		}
		catch (err) {
			console.log(err);
		}

		await initI18n(this.config.languages, this._api);


		this.initialized = true;
	}
}
const portalManager = new PortalManager();
registerComponents({
	PortalView,
	Content,
	LanguageSwitcher
});

export default portalManager;