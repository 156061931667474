import React, { useMemo } from "react";
import { dynamicComponentsRegistry } from "../app/dynamic-components-registry";
import { withDataStores } from "../HOC/withDataStores";
import { withDynamicProps } from "../HOC/withDynamicProps";
import { isDynamic } from "../utils";
import { Content } from "./Content";
interface DynamicComponentProps {
  component: string;
  [key: string]: any;
};
//{ component, stores, children, ...props }: any
//TODO: generate components tree at the app start then just render in dynamic component

export const DynamicComponent: React.FC<DynamicComponentProps> = ({ component = 'Fragment', stores, children, props }) => {
  // console.log({ component, stores, children, props });
  const Component = useMemo(() => {
    let c = dynamicComponentsRegistry[component] || component;
    let hoc = c;
    if (isDynamic(props)) {
      hoc = c.withDynamicProps ? c.withDynamicProps(hoc) : withDynamicProps(hoc);
    }
    if (stores) hoc = withDataStores(hoc, stores);
    
    return hoc;
  }, [component]);
  let reactChildren: any = useMemo(() => {
    // console.log(children)
    if (typeof children === 'string') return <Content content={children} />;
    if (Array.isArray(children)) {
      // console.log(children)
      return children.map((c, i) => <DynamicComponent key={i} {...c} />)
    }
    return children;
  }, [children]);
  // console.log(reactChildren)
  return React.createElement(Component, props, reactChildren);
}