import { Typography, Spin, Row, Col, Table, Descriptions } from "antd";
import { isObject } from "lodash";
import { useTranslation } from "react-i18next";
import { useDynamicData } from "../context/DynamicDataProvider";
import { DynamicProps, useDynamic } from "../HOC/withDynamicProps";
import { parseTemplate } from "../utils/parseTemplate";
import { dynamicComponentsRegistry } from "../app/dynamic-components-registry";
import { useRequest } from "ahooks";
import portalManager from "../app/PortalManager";

const shouldShow = (f: any, data: any) => {
	return !f.meta.hidden && !isObject(data?.[f.field])
}
// const FieldDisplay = ({ data, field, span }: any) => {
// 	const { t } = useTranslation();
// 	const { label, text, component = 'strong', ...props } = parseTemplate(field, data);
// 	const TextComponent = dynamicComponentsRegistry[component] || component;
// 	return <Col span={span}>
// 		<Typography.Text type="secondary">{t(label)}</Typography.Text>
// 		<p><TextComponent {...props}>{text}</TextComponent></p>
// 	</Col>
// }
// export const RecordView = ({ item, fields, columns = 3 }: any) => {
// 	const data = { ...useDynamicData(), item };
// 	return (<Row>
// 		{fields?.map((f: any) => <FieldDisplay data={data} field={f} key={f.label} span={24 / columns} />)}
// 	</Row>
// 	);
// }

export const RecordView = ({ item, fields, ...props }: any) => {
	const { t } = useTranslation();
	const data = { ...useDynamicData(), item };

	return (<Descriptions {...props}>
		{fields?.map((field: any) => {
			const { label, text, hidden = false, component = 'Text', ...props } = parseTemplate(field, data);
			const TextComponent = dynamicComponentsRegistry[component] || component;
			return hidden ? null : <Descriptions.Item label={t(label)} key={label}>
				<TextComponent {...props}>{text}</TextComponent>
			</Descriptions.Item>
		})}
	</Descriptions>
	);
}

RecordView.withDynamicProps = (Component: React.ComponentType<any>) => {
	return ({ children, fields, ...props }: DynamicProps) => {
		const dynamicProps = useDynamic(props);
		return (<Component {...dynamicProps} fields={fields}>{children}</Component>)
	}
}