import {  Space} from "antd";
import React from "react";
import {  WorkflowInstanceAction } from "./WorkflowInstanceAction";
import { Action } from "./WorkflowInstanceActionForm";

interface WorkflowInstanceActionsBarProps {
	workflowInstance: string,
	task: string,
	actions: Action[]
}


export const WorkflowInstanceActionsBar: React.FC<WorkflowInstanceActionsBarProps> = ({ workflowInstance, task, actions }) => {
	console.log("===============>", actions, workflowInstance, task);

	return (<Space>
		{actions && actions?.map(a => <WorkflowInstanceAction key={a.code} action={a} workflowInstance={workflowInstance} task={task}/>)}
	</Space>);
}

