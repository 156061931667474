import { Directus, DirectusOptions, ManyItems, QueryOne } from "@directus/sdk";
import { isDEV } from "../utils";
import { SessionStorage } from "./SessionStorage";
import { DirectusSchema, FieldsDict } from "./types";
import { Field } from "./types/fields";

//export const API_URL = "http://localhost:8055/";

const redirectToLogin = (baseUrl: string) => {
	const redirectUrl = isDEV() ? baseUrl + "sso" : window.location.href;
	window.location.href = baseUrl + "auth/login/keycloak?redirect=" + redirectUrl;
}

export class DirectusClient extends Directus<DirectusSchema> {
  constructor(url: string, options: DirectusOptions = {}) {
    if (isDEV()) {
      options.storage = new SessionStorage();
      options.auth = { mode: "json" }

    }
    super(url, options);
  }

  async initAuth() {
    const refresh = this.auth.refresh;
    this.auth.refresh = async () => {
      try {
        const result = await refresh.apply(this.auth);
        return result;
      }
      catch (err) {
        const redirectUrl = isDEV() ? this.url + "sso" : window.location.href;
        window.location.href = this.url + "auth/login/keycloak?redirect=" + redirectUrl;
      }
      return false;
    }

    if (isDEV()) {
			const t = sessionStorage.getItem("auth_refresh_token");
			if (!t) {
				const urlSearchParams = new URLSearchParams(window.location.search);
				const params = Object.fromEntries(urlSearchParams.entries());
				if (!params.t) redirectToLogin(this.url);
				sessionStorage.setItem("auth_refresh_token", params.t);
			}
		}
		await this.auth.refresh();
		const token = await this.auth.token;
		if (!token) redirectToLogin(this.url);
  }
  
  private _cache: { [key: string]: any } = {};
  async getFields(collection: string): Promise<ManyItems<Field>> {
    const key = `${collection}-fields`;
    if (!this._cache[key]) {
      this._cache[key] = await this.fields.readMany(collection);
    }
    return this._cache[key];
  }
  async getFieldsDict(collection: string): Promise<FieldsDict> {
    const key = `${collection}-fields-dict`;
    if (!this._cache[key]) {
      const fields = await this.getFields(collection);
      this._cache[key] = (fields as unknown as Field[]).reduce(
        (acc: FieldsDict, f) => {
          acc[f.field] = f;
          return acc;
        },
        {}
      );
    }
    return this._cache[key];
  }
  readOne = async ({ collection, id }: { collection: string; id: any }, query: QueryOne) => {
    return await this.items(collection).readOne(id, query);
  }
  readSchema = async ({ collection }: { collection: string }) => {
    return await this.getFieldsDict(collection);
  }
}