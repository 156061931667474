import { Card, Space, Timeline, Typography } from "antd";
import React, { useMemo } from "react";
import { DateDisplay } from "../DateDisplay";
import { useTranslation } from "react-i18next";
import { ClockCircleOutlined } from '@ant-design/icons';
import { Download } from "../Download";

export const ActionsTimeline: React.FC<any> = ({ actions, title, ...props }) => {
	const { t } = useTranslation();
	const items = actions.sort((a: any, b: any) => new Date(a.date_created).getTime() - new Date(b.date_created).getTime()).map((a: any) => ({
		dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
		children: <Space direction="vertical">
			<Typography.Text strong>{t(a.action)}</Typography.Text>
			{/* <Card> */}
			<DateDisplay date={a.date_created} format="DD-MM-YYYY h:mm A" type="secondary" />
			{a.next_task_assignee && <Space>
				<Typography.Text>{t("researcher")}: </Typography.Text>
				<Typography.Text>{a.next_task_assignee.first_name} {a.next_task_assignee.last_name}</Typography.Text>
			</Space>}
			<Space>
				<Typography.Text>{t("By")}: </Typography.Text>
				<Typography.Text>{`${a.user_created.first_name} ${a.user_created.last_name}`}</Typography.Text>
			</Space>
			{a.notes.filter((n: any) => n.note).map((n: any) => <Space key={n.id}>
				<Typography.Text>{t(n.type)}:</Typography.Text>
				<Typography.Text>{t(n.note)}</Typography.Text>
			</Space>)}
			{
				a.files && a.files.length > 0 && <Space align="start">
					<Typography.Text>{t("attachments")}:</Typography.Text>
					<Space direction="vertical">
						{a.files.map(({ attachment, title }: any) => <Space>
							{title && <strong>{t(title)}:</strong>}
							<Download file={attachment} key={attachment.id} />
						</Space>)}
					</Space>
				</Space>
			}
			{/* </Card> */}
		</Space>
	}));

	return (<div style={{}}>

		<Typography.Title level={5}>{title}</Typography.Title>

		<Timeline {...props} items={items} />
		{/* <pre style={{ color: "#eee", backgroundColor: "#555", direction: "ltr" }}>
			{JSON.stringify(actions, null, 2)}
		</pre> */}
	</div>);
}

