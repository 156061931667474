import { Routes, Route, Outlet, Link } from "react-router-dom";
import {
	Badge, Button, Card, Col, Row, Space, Spin, Tag, AutoComplete, Cascader, Checkbox,
	Input, InputNumber, Select, DatePicker, Radio, Switch, TreeSelect, Form as AntdForm, List, Table, Tabs, Layout, Typography,
} from "antd";
import { Menu } from "../components/Menu";
import { TableView } from "../components/TableView";
import { RecordView } from "../components/RecordView";
import { WorkflowInstanceActionsBar } from "../components/workflow/WorkflowInstanceActionsBar";
import { Form } from "../components/Form";
import { RecordForm } from "../components/RecordForm";
import { SubmitButton } from "../components/SubmitButton";
import { DateDisplay } from "../components/DateDisplay";
import { Download } from "../components/Download";
import { ActionsTimeline } from "../components/workflow/ActionsTimeline";
import React from "react";
import { Inbox } from "../components/Inbox";
import { ListView } from "../components/ListView";
import { If } from "../components/If";

export const dynamicComponentsRegistry: Record<string, any> = {
	//default 
	Fragment: React.Fragment,
	//router
	RouterLink: Link,
	Outlet,
	Route,

	//antd
	Card,
	Row,
	Col,
	Spin,
	Badge,
	Tag,
	Space,
	Button,
	AutoComplete,
	Cascader,
	Checkbox,
	Input,
	InputNumber,
	Select,
	DatePicker,
	Radio,
	Switch,
	TreeSelect,
	FormItem: AntdForm.Item,
	List,
	Table,
	Layout,
	LayoutContent: Layout.Content,
	LayoutHeader: Layout.Header,
	LayoutSider: Layout.Sider,
	Text: Typography.Text,
	//custom
	Menu,
	TableView,
	RecordView,
	WorkflowInstanceActionsBar,
	Form,
	RecordForm,
	SubmitButton,
	DateDisplay,
	Download,
	ActionsTimeline,
	ListView,
	If
	// Tabs,
	// TabPane: Tabs.TabPane
}

export const registerComponents = (components: Record<string, any>) => {
	Object.assign(dynamicComponentsRegistry, components);
}
