import { ConfigProvider } from "antd";
import React, { useEffect } from "react";
import { useRouteParams } from "../hooks/useRouteParams";
import { DynamicComponent } from "./DynamicComponent";
import en from "antd/es/locale/en_US";
import ar from "antd/es/locale/ar_EG";
// import { Locale } from "antd/lib/locale-provider";
import { useTranslation } from "react-i18next";
import { createEffect, createStore } from "effector";
import portalManager from "../app/PortalManager";
import { DynamicDataProvider } from "../context/DynamicDataProvider";
import { Locale } from "antd/es/locale";
import { parseTemplate } from "../utils/parseTemplate";

//TODO: handle other languages
const locales: {
  [key: string]: Locale;
} = { ar, en };



export const PortalLayout: React.FC = () => {
  const route = useRouteParams();
  const locale = route.params.locale;
  const { i18n, t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);


  return (<ConfigProvider
    locale={locales[locale as string]}
    direction={i18n.dir()}
    theme={{ token: portalManager.theme }}>
    <DynamicDataProvider data={{
      route,
      i18n: { t, locale: i18n.language },
      user: portalManager.user,
      JSON,
      utils: { parseTemplate }
    }}>
      <DynamicComponent {...portalManager.config.ui} />
    </DynamicDataProvider>
  </ConfigProvider >)
}