import dayjs from "dayjs";
import '../localization/dayjs-ar'
import { useTranslation } from "react-i18next";
import timezone from 'dayjs/plugin/timezone';
import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
dayjs.extend(timezone)
dayjs.tz.guess()

interface DateDispalyProps extends TextProps {
	date: string;
	format?: string;

}
export const DateDisplay: React.FC<DateDispalyProps> = ({ date, format = "DD-MM-YYYY", ...props }) => {
	const { i18n } = useTranslation();
	return <Typography.Text {...props}> {dayjs(date).locale(i18n.language).format(format)}</Typography.Text>;
}