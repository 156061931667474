import { useTranslation } from "react-i18next";
import portalManager from "../app/PortalManager";
import { useState } from "react";
import { useAsyncEffect } from "ahooks";


export const Download = ({ file }: any) => {
	const { t } = useTranslation();
	const [token, setToken] = useState("");
	useAsyncEffect(async () => {
		setToken((await portalManager.api.auth.token)!);
	}, [])
	if (!file) return null;
	return <a href={`${portalManager.api.url}assets/${file.id}?download&access_token=${token}`} target="_blank" rel="noreferrer" download={file.filename_download}>{file.filename_download}</a>;
}