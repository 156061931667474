import { Button, Form, FormInstance, Input, Spin, Upload } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupUsersDropdown } from "./GroupUsersDropdown";
import { isArray } from "lodash";
import portalManager from "../../app/PortalManager";
import { useAsyncEffect } from "ahooks";
import { UploadOutlined } from '@ant-design/icons';
export interface Note {
	type?: string;
	showToFacility?: boolean;
	required?: boolean;
}

export interface Attachment {
	title: string;
}
export interface ActionUI {
	button: any;
	type: string;
	notes?: Note[];
	attachments?: boolean | Attachment[];
	next_task_assignee_group?: string;
	path?: string;
	view?: string;
}
export interface Action {
	code: string,
	assign_next_task: boolean;
	ui: ActionUI;
}


export interface WorkflowInstanceActionFormProps {
	workflowInstance: string,
	task: string,
	action: Action,
	form: FormInstance,
	submit: () => void

}
const FileUpload = ({ single = false, ...props }: any) => {
	const { t } = useTranslation();
	const [token, setToken] = useState<string>();
	const fileList = [];

	useAsyncEffect(async () => {
		const token = await portalManager.api.auth.token;
		setToken(token as string);
	}, [portalManager.api.auth]);
	if (!token) return <Spin />
	const headers = {
		Authorization: `Bearer ${token}`
	}
	// @ts-ignore
	return <Upload {...props} multiple={!single} action={portalManager.api.url + "files"} headers={headers}>
		<Button icon={<UploadOutlined />}>{t(single ? "SelectFile" : "AddAttachments")}</Button>
	</Upload>
}

export const WorkflowInstanceActionForm: React.FC<WorkflowInstanceActionFormProps> = ({ workflowInstance, task, action, form, submit }) => {
	const { t } = useTranslation();
	const notes = action.ui?.notes?.map(({ type = 'note', showToFacility = false }) => ({ type, showToFacility }))
	return (
		<Form form={form} onFinish={submit} layout="vertical">
			<Form.Item name="action" initialValue={action.code} hidden />
			<Form.Item name="task" initialValue={task} hidden />
			<Form.Item name="workflow_instance" initialValue={workflowInstance} hidden />
			{action.ui?.next_task_assignee_group && <GroupUsersDropdown group={action.ui?.next_task_assignee_group} name="next_task_assignee" />}
			<Form.List name="notes" initialValue={notes}>
				{(fields) => (
					<>
						{fields.map(({ key, name }, index) => (
							<React.Fragment key={key}>
								<Form.Item
									label={t(action.ui?.notes?.[index]?.type || "notes")}
									name={[name, 'note']}
									rules={[{ required: action.ui?.notes?.[index]?.required }]}>
									<Input.TextArea />
								</Form.Item>
								<Form.Item name={[name, 'showToFacility']} hidden />
								<Form.Item name={[name, 'type']} hidden />
							</React.Fragment>
						))}
					</>
				)}
			</Form.List>
			{action.ui?.attachments && <>
				{isArray(action.ui?.attachments) && action.ui?.attachments.map((a, index) => <React.Fragment key={a.title}>
					<Form.Item name={['attachments', index, 'title']} initialValue={a.title} hidden />
					<Form.Item label={t(a.title)} name={['attachments', index, 'attachment']} required>
						<FileUpload single />
					</Form.Item>
				</React.Fragment>)}
				<Form.Item name="otherAttachments">
					<FileUpload />
				</Form.Item>
			</>}
		</Form>
	);
}
