
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, Select } from "antd";
import { useRequest } from "ahooks";
import portalManager from "../../app/PortalManager";
import { useAsyncCallback } from "../../hooks/useAsyncCallback";


export interface GroupUsersDropdownProps {
	group: string;
	name: string;
}




export const GroupUsersDropdown: React.FC<GroupUsersDropdownProps> = ({ group, name }) => {
	const { t } = useTranslation();
	//const [options, setOptions] = useState([]);

	const [getData] = useAsyncCallback(async () => {
		const res = await portalManager.api.items("users_groups").readOne(group, { fields: ["users.directus_users_id.id", "users.directus_users_id.first_name", "users.directus_users_id.last_name"] });
		return res.users.map((u: any) => ({
			label: `${u.directus_users_id.first_name || ''} ${u.directus_users_id.last_name || ''}`,
			value: u.directus_users_id.id
		}))
	}, []);
	const { data, loading, refresh } = useRequest(getData);
	return (<Form.Item label={t('selectUser')} name={name} rules={[{ required: true }]}>
		<Select options={data} />
	</Form.Item>
	);
}
