import { BaseStorage } from "@directus/sdk";

export class SessionStorage extends BaseStorage {
	get(key: string) {
		return sessionStorage.getItem(key);
	}
	set(key: string, value: string): any {
		return sessionStorage.setItem(key, value);
	}
	delete(key: string): any {
		return sessionStorage.removeItem(key);
	}
}