import { Spin, Table as AntdTable } from 'antd';
import { TableProps as AntdTableProps, ColumnProps as AntdColumnProps } from 'antd/lib/table'
import { ColumnType } from 'antd/lib/table';
import { Store } from 'effector';
import { useEffect, useMemo, useState } from 'react';
import { createDataStore, DataStore } from '../app/data-store';
import portalManager from '../app/PortalManager';
import { useDynamicData } from '../context/DynamicDataProvider';
import { DynamicProps, useDynamic } from '../HOC/withDynamicProps';
import { isDynamic } from '../utils';
import { parseTemplate } from '../utils/parseTemplate';
import { Content } from './Content';
import { DynamicComponent } from './DynamicComponent';


export interface TableProps<T extends object = Record<string, any>> extends AntdTableProps<T> {
	columns: any[],
	tableProps: AntdTableProps<any>
}

const DynamicCell = ({ item, ...template }: any) => {
	const data = useDynamicData();
	const props = parseTemplate(template, { ...data, item });
	return <DynamicComponent {...props} />
}

export const TableView = ({ columnsCacheId, dataSource, columns, ...props }: any) => {
	if (!dataSource) return <Spin />;
	return (<AntdTable columns={columns} dataSource={[...(dataSource || [])]} {...props} />);
};
TableView.withDynamicProps = (Component: React.ComponentType<any>) => {
	return ({ children, columns: columnsProps, ...props }: DynamicProps) => {
		const dynamicProps = useDynamic(props);
		const columns = useMemo(() => {
			return columnsProps.map(({ template, title, ...c }: any, index: number) => {
				if (isDynamic({ title })) title = <Content content={title} />;
				if (template) {
					return {
						...c,
						title,
						render: (text: any, item: any) => {
							return <DynamicCell  {...template} item={item} />
						}
					}
				}
				return { ...c, title };
			})
		}, [columnsProps]);

		return (<Component {...dynamicProps} columns={columns}>{children}</Component>)
	}
}
// TableView.processProps = ({ component, stores, children, ...props }: any, dataStores: Record<string, DataStore>): Record<string, any> => {
// 	const renderFunctions: Record<any, any> = {};
// 	props.columns = props.columns.map(({ template, ...c }: any, index: number) => {
// 		if (template) {
// 			renderFunctions[c.key || index] = (text: any, item: any) => {
// 				template.stores = (template.stores || []).concat(['i18n', { value: item, name: 'item', type: 'data' }]);
// 				const cellProps = getDynamicComponentProps(template, dataStores);
// 				return <DynamicComponent  {...cellProps} />
// 			};
// 			return {
// 				...c,
// 				render: "${{render['" + (c.key || index) + "']}}"
// 			}
// 		}
// 		return c;
// 	})
// 	const renderStore = {
// 		name: 'render',
// 		type: 'data',
// 		value: renderFunctions
// 	}
// 	dataStores['render'] = createDataStore(dataStores, renderStore, portalManager.api);
// 	return props;
// };

