import { Button } from "antd";
import { useTranslation } from "react-i18next"
import { useFormContext } from "./Form";
export const SubmitButton = (props: any) => {
	const { t } = useTranslation();
	const { busy } = useFormContext();

	return (<Button type="primary" htmlType="submit" {...props} loading={busy}>
		{t(props?.label || "submit")}
	</Button>);
}

/*
{
		"component": "Button",
		"props": {
				"htmlType": "submit",
				"type": "primary"
		},
		"children": [
				{
						"component": "Content",
						"props": {
								"translate": true,
								"content": "submit"
						}
				}
		]
}
*/