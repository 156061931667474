
import { createContext, useContext } from "react";
import React from "react";

type DynamicDataProviderValue = Record<string, any>

const Context = createContext<DynamicDataProviderValue>({});
export const useDynamicData = (key?: string) => {
	const value = useContext(Context);
	if (key) return value[key];
	return value;
};

interface DynamicDataProviderPrpos {
	children?: React.ReactNode,
	data: any
}
export const DynamicDataProvider: React.FC<DynamicDataProviderPrpos> = ({ children, data = {} }) => <Context.Provider value={{ ...data }}>{children}</Context.Provider>;
