// @ts-ignore
import parseTemplate from 'json-json-template';
import { useDynamicData } from '../context/DynamicDataProvider';


export interface DynamicProps {
	[key: string]: any;
	children?: any;
}

export const useDynamic = (props: Record<string, any>) => {
	const data = useDynamicData();
	return parseTemplate(props)(data);
}
export const withDynamicProps = (Component: React.ComponentType<any>) => {
	return ({ children, ...props }: DynamicProps) => {
		const dynamicProps = useDynamic(props);
		return (<Component {...dynamicProps}>{children}</Component>)
	}
}