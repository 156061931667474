import { useQuery, useQueryClient } from '@tanstack/react-query'
import portalManager from '../app/PortalManager';
import { Spin } from 'antd';
import { createQueryKeys, createQueryKeyStore } from "@lukemorales/query-key-factory";
import { isDynamic } from '../utils';
import { DynamicDataProvider, useDynamicData } from '../context/DynamicDataProvider';
import { parseTemplate } from '../utils/parseTemplate';
import { useRequest } from 'ahooks';

function timeout() {
	return new Promise(resolve => setTimeout(resolve, 2000));
}

// const apiFields = (name: string, { collection }: any) => ({
// 	queryKey: [collection],
// 	queryFn: async () => {
// 		if (!collection) return [];
// 		return (await portalManager.api.fields.readMany(collection)).data
// 	},
// });
// const apiList = (name: string, { collection, fields, filter }: any) => ({
// 	queryKey: [collection, fields, filter],
// 	queryFn: async () => {
// 		if (!collection) return [];
// 		const res = await portalManager.api.items(collection).readByQuery({ fields: fields || ["*.*"], filter });
// 		return res.data;
// 	},
// });

// const apiRecord = (name: string, { collection, fields, id }: any) => ({
// 	queryKey: [collection, fields, id],
// 	queryFn: async () => {
// 		if (!collection || !id) return {};
// 		const res = await portalManager.api.items(collection).readOne(id, { fields: fields || ["*.*"] });
// 		return res;
// 	},
// })

// const apiPost = (name: string, { path = "", ...data }: any) => ({
// 	queryKey: [path, data],
// 	queryFn: async () => {
// 		return await portalManager.api.transport.post(path, data);
// 	},
// })


// const dataLoaders: Record<string, (name: string, options: any) => any> = {
// 	apiFields,
// 	apiRecord,
// 	apiList,
// 	apiPost,
// 	data: (name: string, { initialValue }: any) => {
// 		let value = initialValue;
// 		return {
// 			queryKey: [name],
// 			queryFn: () => value
// 		}
// 	}
// }


const apiFields = async ({ collection }: any) => (await portalManager.api.fields.readMany(collection)).data;
const apiList = async ({ collection, fields, filter, aggregate, sort }: any) => (
	await portalManager.api.items(collection).readByQuery({ fields: fields || ["*.*"], filter, aggregate, sort })).data;
const apiRecord = async ({ collection, fields, id }: any) => await portalManager.api.items(collection).readOne(id, { fields: fields || ["*.*"] });
const apiPost = async ({ path = "", ...data }: any) => await portalManager.api.transport.post(path, data);
const apiPatch = async ({ path = "", data }: any) => await portalManager.api.transport.patch(path, data);



const dataLoaders: Record<string, (p: any) => any> = {
	apiRecord,
	apiFields,
	apiList,
	apiPost,
	apiPatch,
	data: ({ initialValue }: any) => initialValue
}

export const withDataStore = (Component: React.ComponentType<any>, store: any) => {
	return (props: any) => {
		const allData = useDynamicData();
		const options = isDynamic(store.options) ? parseTemplate(store.options, allData) : store.options;
		// const { isLoading, data } = useQuery(dataLoaders[store.type](store.name, options));
		const { data, loading: isLoading } = useRequest(async () => await dataLoaders[store.type](options), { pollingInterval: store.refresh });
		if (isLoading && !store.noLoading) return <Spin />;
		return (<DynamicDataProvider data={{ ...allData, [store.name]: data }}>
			<Component {...props} />
		</DynamicDataProvider>)
	}
}


export const withDataStores = (Component: React.ComponentType<any>, stores: any[]) => {
	return [...stores].reverse().reduce((acc, store) => acc = withDataStore(acc, store), Component)
}