import { QueryClientProvider } from "@tanstack/react-query";
import { Button, Result, Spin } from "antd";
import React, { useEffect, useMemo } from "react";
import { createBrowserRouter, createMemoryRouter, Navigate, RouterProvider, useNavigate, useParams } from "react-router-dom";
import portalManager, { queryClient } from "../app/PortalManager";
import { DynamicComponent } from "./DynamicComponent";
import { PortalLayout } from "./PortalLayout";

const Refresh = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => navigate(-1), 500);
  })
  return <Spin />;
}

export const Portal: React.FC = () => {
  const createRouter = portalManager.config.useMemoryRouter ? createMemoryRouter : createBrowserRouter
  const routes = useMemo(() => createRouter([{
    path: "/:locale",
    element: <PortalLayout />,
    children: [{
      path: "refresh",
      element: <Refresh />
    }, ...portalManager.config.routes.map(({ path, element }: any) => ({ path, element: <DynamicComponent {...element} /> }))],
  },
  { path: "/", element: <Navigate to={"/" + portalManager.defaultLanguage + "/" + (portalManager.config.defaultPath || "")} /> },
  {
    element: <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary">Back Home</Button>}
    />
  }]), []);
  return <QueryClientProvider client={queryClient}>
    <RouterProvider router={routes} fallbackElement={<Spin />} />
  </QueryClientProvider>;
};