import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Action, WorkflowInstanceActionForm } from "./WorkflowInstanceActionForm";
import { Link, Route, useLocation, useNavigate } from "react-router-dom";
import portalManager from "../../app/PortalManager";
import { useAsyncCallback } from "../../hooks/useAsyncCallback";
import { useDynamicData } from "../../context/DynamicDataProvider";
import { useRefreshCurrentRoute } from "../../hooks/useRefreshCurrentRoute";
import { PortalView } from "../PortalView";
import { isArray } from "lodash";

export interface WorkflowInstanceActionProps {
	workflowInstance: string,
	task: string,
	action: Action
}




export const WorkflowInstanceAction: React.FC<WorkflowInstanceActionProps> = ({ workflowInstance, action, task }) => {
	const { t } = useTranslation();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [form] = Form.useForm();
	let refresh = useRefreshCurrentRoute();
	const [submit] = useAsyncCallback(async () => {
		const { attachments, otherAttachments, ...data } = form.getFieldsValue(true);
		data.files = [];
		if (isArray(attachments)) {
			attachments.forEach(({ title, attachment }) => {
				data.files.push({
					title,
					attachment: attachment.file.response.data.id
				})
			})
		}
		if(isArray(otherAttachments?.fileList)){
			otherAttachments.fileList.forEach((f:any) => {
				data.files.push({
					attachment: f.response.data.id
				})
			});
		}
		

		console.log(data)
		const res = await portalManager.api.items("workflow_instances_actions").createOne(data);
		refresh();
	}, []);
	const navigate = useNavigate();
	if (!workflowInstance) return null;
	const handleOk = async () => {
		await form.validateFields();
		await submit();
		setIsModalOpen(false);
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const onButtonClick = () => {
		if (action?.ui?.type === "route") {
			navigate(action.ui.path!)
		}
		else if (action?.ui?.type === "modal") {
			setIsModalOpen(true);
		}
		else {
			submit();
		}
	}
	const formEl = <WorkflowInstanceActionForm workflowInstance={workflowInstance} task={task} submit={submit} form={form} action={action} />;
	return (<>
		{action.ui?.type === "button" && formEl}
		{
			action.ui?.type === "modal" && <Modal title={t(action.code)} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				{formEl}
			</Modal>
		}

		<Button  {...action.ui?.button} onClick={onButtonClick}>{t(action.code)}</Button>
	</>
	);
}

