import { useAsyncEffect } from "ahooks";
import { Button, Checkbox, DatePicker, Form, FormInstance, Input, InputNumber, Radio, Select, Spin, Upload } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, FieldMeta } from "../directus/types/fields";
import { UploadOutlined } from '@ant-design/icons';
import { isUndefined, omit, omitBy } from "lodash";
import portalManager from "../app/PortalManager";



export const gridSpans = {
	half: 2,
	"half-left": 2,
	"half-right": 2,
	full: { span: 18 },
	fill: 5,
};
export interface FileUploadProps {
	field: Field;
	fieldOptions: FieldOptions;
	form: FormInstance;
	[key: string]: any
}
const FileUpload = ({ field, fieldOptions, form, value, ...props }: FileUploadProps) => {
	console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
	console.log(field, fieldOptions, form, props)
	const { t } = useTranslation();
	const [token, setToken] = useState<string>();
	const fileList = [];
	if (value)
		fileList.push({
			uid: value.id,
			name: value.filename_download,
			status: 'done',
			url: `${portalManager.api.url}assets/${value.id}?download&access_token=${token}`
		});
	useAsyncEffect(async () => {
		const token = await portalManager.api.auth.token;
		setToken(token as string);
		if (value) {
			console.log("Value here", value)
		}
	}, [portalManager.api.auth]);
	if (!token) return <Spin />
	const headers = {
		Authorization: `Bearer ${token}`
	}
	// @ts-ignore
	return <Upload {...props} defaultFileList={fileList} headers={headers}>
		<Button icon={<UploadOutlined />}>{t(field.meta?.interface !== 'files' ? "SelectFile" : "AddFile")}</Button>
	</Upload>
}
export const typeMapping: any = {
	integer: "number"
}
export const componentMapping: any = {
	input: Input,
	input_integer: InputNumber,
	"input-multiline": Input.TextArea,
	"select-dropdown": Select,
	"select-radio": Radio.Group,
	"select-dropdown-m2o": Select,
	datetime: DatePicker,
	"file-image": FileUpload,
	file: FileUpload,
	files: FileUpload,
	boolean: Checkbox,
	"input-hash": Input.Password
}

export const formGridProps = {
	minColumns: 2,
	maxColumns: 5,
	strictAutoFit: true,
};
export interface FieldOptions {
	optionsLabelField?: string,
	optionsValueField?: string
}
export const useRules = (field: Field, fieldOptions: FieldOptions) => {
	const rules: any[] = [];
	rules.push({ required: field.meta?.required });
	return rules;

}
export const useFormItemProps = (field: Field, fieldOptions: FieldOptions, namePrefix: string[]) => {
	const { t } = useTranslation();
	const rules = useRules(field, fieldOptions);
	const props: any = {
		rules,
		name: [...namePrefix, field.field],
		//wrapperCol: { span: 24 },//gridSpans[field.meta.width || 'full'],
		//hasFeedback: true,
	}
	if (field.meta?.interface !== 'boolean') props.label = t([`${field.collection}.${field.field}`, field.field]);
	return omitBy(props, isUndefined);
}
export const useOptions = (field: Field, fieldOptions: FieldOptions) => {
	const { t, i18n } = useTranslation();
	const defaultOptions = field.meta?.options?.choices?.map?.(({ text, value }: any) => ({ value, label: t(text.replace("$t:", "")) }))
	const [options, setOption] = useState<any>(defaultOptions);
	useAsyncEffect(async () => {
		if (field.meta?.interface !== 'select-dropdown-m2o') return;
		const response = await portalManager.api.items(field.schema.foreign_key_table as string).readByQuery({ fields: "*", limit: -1 });
		const labelField = fieldOptions?.optionsLabelField || 'name_' + i18n.language;
		const valueField = fieldOptions?.optionsValueField || 'code';
		setOption(response.data?.map(item => ({ label: item[labelField] || item.name, value: item[valueField] || item.id })))
	}, [i18n.language])
	return options;
}

export const useFileInputProps = (field: Field, fieldOptions: FieldOptions): any => {
	if (!['file', 'file-image', 'files'].includes(field.meta?.interface || "")) return null;
	const props: any = { action: portalManager.api.url + "files", field, fieldOptions };
	if (field.meta?.interface !== 'files') props.maxCount = 1;
	return props;
}

export const useInputProps = (field: Field, fieldOptions: FieldOptions) => {
	const { t } = useTranslation();
	const options = useOptions(field, fieldOptions);
	const fileInputProps = useFileInputProps(field, fieldOptions);

	const props = { ...fileInputProps, options };
	if (field.meta?.interface === 'boolean') props.children = t(`${field.collection}.${field.field}`, field.field);
	return omitBy(props, isUndefined);
}


export interface FormFieldProps {
	field: Field,
	form: FormInstance,
	fieldOptions: FieldOptions,
	namePrefix: string[]
}
export const FormField = ({ field, fieldOptions, namePrefix, form }: FormFieldProps) => {
	let FieldWidget = componentMapping[field.meta.interface + '_' + field.type] || componentMapping[field.meta.interface ?? "input"] || Input;
	const inputProps = useInputProps(field, fieldOptions);
	const formItemProps = useFormItemProps(field, fieldOptions, namePrefix);
	return <Form.Item {...formItemProps} >
		<FieldWidget form={form} {...inputProps} />
	</Form.Item>
}
export const RecordForm = ({ collection, operation = "create", id, fieldsNamesPrefix = [], fieldsOptions }: any) => {
	const { t } = useTranslation();
	const form = Form.useFormInstance();
	const [fields, setFields] = useState<any>(null);
	useAsyncEffect(async () => {
		if (!collection) return;
		// await portalManager.api.items("workflow_instances").createOne({
		// 	"id": "748238FA-A3DC-4B41-96A2-18D0D6F32498",
		// 	"workflow": "WORKFLOW_EIA_REVIEW_STUDIES",
		// 	"data": [
		// 		{
		// 			"collection": "EIA_studies",
		// 			"item": "748238FA-A3DC-4B41-96A2-18D0D6F32498"
		// 		}
		// 	],
		// 	"status": "STATUS_NEW",
		// 	"tenant": "F81BA0AB-2129-423A-A5FC-80B8C099289F"
		// })
		const apiFields = await portalManager.api.getFields(collection);
		setFields(apiFields.data?.sort((a, b) => (a.meta as FieldMeta).sort - (b.meta as FieldMeta).sort));
	}, [collection]);
	if (!fields) return <Spin />;
	return (<>
		{
			fields.map((field: Field) => (!field.meta.hidden && <FormField key={field.field} field={field} form={form} namePrefix={fieldsNamesPrefix} fieldOptions={fieldsOptions?.[field.field]} />))
		}
		{/* <pre style={{ direction: 'ltr', backgroundColor: "#555", color: "#eee", padding: '20px', marginTop: "30px" }}>
			{JSON.stringify(fields, null, 2)}
		</pre> */}
	</>);
}

//logout: https://sso.giscon.dev/auth/realms/test/protocol/openid-connect/logout