import { useRequest } from "ahooks";
import { Spin } from "antd";
import { views } from "../portal-sample";
import { DynamicComponent } from "./DynamicComponent";

interface PortalViewProps {
	view: string;
}
export const PortalView: React.FC<PortalViewProps> = ({ view }) => {
	const { data: viewConfig, loading } = useRequest(async () => {
		console.log("loading view ...")
		// @ts-ignore
		const code = await (await fetch(`/portals/eia/views/${view}.js`)).text();
		/* eslint-disable no-eval */
		return eval(code);
	}, { refreshDeps: [view] });
	if (loading) return <Spin />
	return (<DynamicComponent {...viewConfig} />);
}