import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './localization/i18n';
import { App } from './App';
import "./index.css";
const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	//<React.StrictMode>
	<App />
	//</React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
