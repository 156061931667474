import i18n, { ResourceLanguage } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { BackendModule, MultiReadCallback, ReadCallback } from "i18next";
import { DirectusClient } from "../directus/DirectusClient";

// import Backend from "i18next-http-backend";
class Backend implements BackendModule {
  type!: 'backend';
  static type = 'backend';
  api!: DirectusClient;
  create?(languages: readonly string[], namespace: string, key: string, fallbackValue: string): void {
    throw new Error("Method not implemented.");
  }

  save?(language: string, namespace: string, data: ResourceLanguage): void {
    //throw new Error("Method not implemented.");
  }

  init(services: any, options: { api: DirectusClient }) {
    // console.log(services, options);
    this.api = options.api;
  }
  readMulti(languages: string[], namespaces: string[], callback: MultiReadCallback) {
    console.log("Backend Read Multi", languages, namespaces)
  }
  read(language: string, namespace: string, callback: ReadCallback) {
    this.api.items("translations").readByQuery({
      fields: ["key", "label"],
      limit: -1,
      filter: {
        locale: {
          _eq: language
        }
      }
    }).then((translations) => {
      const labels: any = {};
      translations?.data?.forEach(item => {
        labels[item.key] = item.label
      })
      callback(null, labels);
    })

  }

}
export const initI18n = async (supportedLngs: string[], api: DirectusClient) => {
  await i18n.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: false,
      supportedLngs,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: { api },
    });
}


// export const LocalizationInitializar: React.FC = (props: any) => {
//   const [loading, setLoading] = useState(true);
//   const api = useApi();
//   useAsyncEffect(async () => {
//     if (api) {
//       await initI18n(api);
//       setLoading(false);
//     }

//   }, [])
//   if (loading) return <AppLoadingMask />;
//   return <>{props.children}</>;
// };
