
import { Menu as AntdMenu } from "antd";
import { useMemo } from "react";
import { DynamicProps, useDynamic } from "../HOC/withDynamicProps";
import { DynamicComponent } from "./DynamicComponent";

export const Menu = ({ items = [], ...props }: any) => {
	// console.log(items)
	const menuItems = useMemo(() => {
		return items.map((i: any) => {
			const item = { ...i };
			if (typeof i.label === 'object') {
				item.label = <DynamicComponent {...i.label} />
			}
			return item;
		})
	}, [items]);
	return (<AntdMenu {...props} items={menuItems} />);

}

Menu.withDynamicProps = (Component: React.ComponentType<any>) => {
	return ({ children, items, ...props }: DynamicProps) => {
		const dynamicProps = useDynamic(props);
		return (<Component {...dynamicProps} items={items}>{children}</Component>)
	}
}