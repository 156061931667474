import { useTranslation } from "react-i18next";
import { Link, useLocation, useMatches, useParams } from "react-router-dom";
import portalManager from "../app/PortalManager";

export const LanguageToggler = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const otherLanguage = portalManager.languages.find((lang: string) => lang !== i18n.language);
  const to = location.pathname.replace("/" + i18n.language, "/" + otherLanguage) + location.search + location.hash;
  return (
    <Link
      className="ant-btn ant-btn-default"
      style={{ margin: "0 10px" }}
      to={to}>
      <span>{t(otherLanguage!)}</span>
    </Link>
  );
};

export const LanguageSwitcher = () => {
  return <LanguageToggler />
  // const { i18n } = useTranslation();
  // console.log(i18n.languages)
  // const location = useLocation();
  // const { locale } = useParams();
  // const to = location.pathname.replace("/" + locale, locale === "ar" ? "/en" : "/ar") + location.search + location.hash;
  // return (
  //   <Link
  //     className="ant-btn ant-btn-default"
  //     style={{ margin: "0 10px" }}
  //     to={to}
  //   >
  //     <span>Switch Language</span>
  //   </Link>
  // );
};