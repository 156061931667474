import { useAsyncEffect } from "ahooks";
import React, { useState } from "react";
import portalManager from "./app/PortalManager";
import AppLoadingMask from "./components/AppLoadingMask";
import { Portal } from "./components/Portal"

export const App: React.FC = () => {
	const [ready, setReady] = useState(false);
	useAsyncEffect(async () => {
		if (portalManager.initialized) return;
		await portalManager.init();
		setReady(true);
	}, []);
	if (!ready) return <AppLoadingMask />;

	return <Portal />;
};